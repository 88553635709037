import { css } from 'styled-components';

export const microsoftBookings = css`
  .microsoft-bookings {
    iframe {
      border: 0;
      width: 100%;
      min-height: 100%;
      background: transparent;
    }
  }
`;
