import { getSectionSpacing, Section, textStyle } from '@elseu/sdu-titan-web-commerce';
import type { SectionProps } from 'components/Section';
import type { SectionForm as ContentfulSectionForm } from 'generated/graphql';
import getConfig from 'next/config';
import Script from 'next/script';
import React, { useEffect, useRef } from 'react';
import sanitizeHtml from 'sanitize-html';
import styled, { css } from 'styled-components';

import { eaadLeadgeneration } from './styles/eaad-leadgeneration';
import { eaadPlaceholder } from './styles/eaad-placeholder';
import { microsoftBookings } from './styles/microsoft-bookings';

const nextConfig = getConfig();
const basePath = nextConfig?.publicRuntimeConfig?.basePath || process.env.BASE_PATH;

type SectionFormProps = Pick<ContentfulSectionForm, 'snippet' | 'name' | 'kind'> & SectionProps;

declare global {
  interface Window {
    initialize?: (snippet: string) => void;
    embeds?: Set<unknown>;
  }
}

/**
 * A custom hook to clean up the 'hey-flow' embeds when the component is unmounted.
 * @param {Object} param0 - The parameters object.
 * @param {string} param0.kind - The form kind.
 */
const useHeyFlowCleanup = ({ kind }: { kind?: string }) => {
  useEffect(() => {
    if (kind === 'hey-flow') {
      return () => {
        /** remove embeds from the window object */
        delete window.embeds;
      };
    }
  }, [kind]);
};

/**
 * A custom hook to initialize the checkboxes in 'eaad-newsletter' and 'eaad-lead-generation' forms.
 * @param {Object} param0 - The parameters object.
 * @param {string} param0.kind - The form kind.
 * @param {React.RefObject<HTMLDivElement>} param0.sectionFormRef - A ref to the section form div element.
 */
const useEaadCheckboxInitialization = ({
  kind,
  sectionFormRef,
}: {
  kind?: string;
  sectionFormRef: React.RefObject<HTMLDivElement>;
}) => {
  useEffect(() => {
    if ((kind === 'eaad-newsletter' || kind === 'eaad-lead-generation') && sectionFormRef.current) {
      const updateCheckboxIds = () => {
        const checkboxes = sectionFormRef.current?.querySelectorAll('input[type="checkbox"]');

        checkboxes?.forEach((checkbox) => {
          const inputElement = checkbox as HTMLInputElement;

          if (!inputElement.id) {
            inputElement.id = inputElement.name;
          }
        });
      };

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList') {
            updateCheckboxIds();
          }
        });
      });

      observer.observe(sectionFormRef.current, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [kind, sectionFormRef]);
};

/**
 * A custom hook to load 'eaad' scripts and remove them when the component is unmounted.
 * @param {Object} param0 - The parameters object.
 * @param {string} param0.kind - The form kind.
 * @param {string} param0.snippet - The script snippet.
 */
const useEaadScriptLoader = ({ kind, snippet }: { kind?: string; snippet?: string }) => {
  useEffect(() => {
    if (kind === 'eaad-newsletter' || kind === 'eaad-lead-generation') {
      const baseUrl = basePath.includes('sduoneplatform')
        ? 'https://emailpreferences.acceptatie.sdu.nl'
        : 'https://emailpreferences.sdu.nl';
      const loadScript = () => {
        const scriptSrc =
          kind === 'eaad-lead-generation'
            ? `${baseUrl}/widgets/lead-generation-widget.js`
            : `${baseUrl}/widgets/newsletter-widget.js`;

        if (snippet) {
          const scriptElement = document.createElement('script');
          scriptElement.src = scriptSrc;
          scriptElement.id = `script-${kind}`;
          scriptElement.async = true;
          scriptElement.onload = () => {
            window.initialize?.(snippet);
          };
          document.body.appendChild(scriptElement);
        }
      };

      loadScript();

      return () => {
        const scriptElement = document.getElementById(`script-${kind}`);
        if (scriptElement) {
          scriptElement.remove();
        }
      };
    }
  }, [kind, snippet]);
};

const StyledSectionForm = styled(Section)`
  ${({ theme }) => css`
    ${getSectionSpacing({ theme, xPadding: 8, yPadding: 8, maxWidth: '1200px' })}

    * {
      ${textStyle('label')}
    }

    label {
      ${textStyle('labelBold')}
    }

    h2 {
      ${textStyle('headerLarge')}
    }

    input {
      border: 1px solid #dfd8c1;
      border-radius: 4px;
      padding: ${theme.spacing[3]} ${theme.spacing[4]};
    }

    ${eaadLeadgeneration({ theme })}

    ${eaadPlaceholder({ theme })}

    ${microsoftBookings}

    .sdu-eaad-error {
      color: ${theme.designTokens.colors.danger50};
    }

    button {
      cursor: pointer;
      margin-top: ${theme.spacing[4]};
      margin-left: auto;
      background: ${theme.designTokens.colors.primary80};
      color: white;
      border-radius: ${theme.borderRadius[2]};
      border: 0;
      padding: ${theme.spacing[4]} ${theme.spacing[8]};

      &:hover {
        background: ${theme.designTokens.colors.primary60};
      }
    }
  `}
`;

const heyFlowSanitizeOptions = {
  allowedTags: ['heyflow-wrapper'],
  allowedAttributes: {
    'heyflow-wrapper': ['*'],
  },
};

const microsoftBookingsSanitizeOptions = {
  allowedTags: ['iframe'],
  allowedAttributes: {
    iframe: ['*'],
  },
  allowedIframeHostnames: ['outlook.office365.com'],
};

/**
 * The SectionForm component renders a form based on the given 'kind' prop.
 * It utilizes custom hooks to manage form-specific logic for 'hey-flow' and 'eaad' forms.
 * @component
 * @example
 * <SectionForm snippet={snippet} name={name} id={id} background={background} kind={kind} />
 *
 * @param {SectionFormProps} props - The props for the SectionForm component.
 * @param {string} props.snippet - The script snippet.
 * @param {string} props.name - The form name.
 * @param {string} props.id - The form ID.
 * @param {string} props.background - The form background color.
 * @param {string} props.kind - The form kind, e.g. 'hey-flow', 'eaad-newsletter', 'eaad-lead-generation'.
 * @returns {React.ReactNode} The rendered SectionForm component.
 */
export const SectionForm: React.FC<SectionFormProps> = ({
  snippet,
  name,
  id,
  background,
  kind,
}) => {
  const sectionFormRef = useRef<HTMLDivElement>(null);

  useHeyFlowCleanup({ kind });
  useEaadCheckboxInitialization({ kind, sectionFormRef });
  useEaadScriptLoader({ kind, snippet });

  return snippet ? (
    <>
      {kind === 'hey-flow' && (
        <Script
          src="https://static.heyflow.app/widget/latest/webview.js"
          strategy="afterInteractive"
        />
      )}
      <StyledSectionForm
        name={name}
        reference={id}
        background={background as 'sand' | 'white'}
        ref={sectionFormRef}
      >
        {snippet && kind === 'hey-flow' && (
          <div
            suppressHydrationWarning
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(snippet, heyFlowSanitizeOptions),
            }}
          />
        )}
        {snippet && kind === 'eaad-newsletter' && <div id="sdu-eaad-placeholder" />}
        {snippet && kind === 'eaad-lead-generation' && <div id="sdu-eaad-leadgeneration" />}
        {snippet && kind === 'microsoft-bookings' && (
          <div
            className="microsoft-bookings"
            suppressHydrationWarning
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(snippet, microsoftBookingsSanitizeOptions),
            }}
          />
        )}
      </StyledSectionForm>
    </>
  ) : null;
};
